import request from '@/utils/request'
export const getDashboardPatient = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/dashboard/patient',
    params,
    hasLoading
  })
}

export const getDashboardBed = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/dashboard/bed',
    params,
    hasLoading
  })
}

export const getDashboardDischarge = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/dashboard/discharge',
    params,
    hasLoading
  })
}