/**
 * 用户相关请求模块
 */

import request from '@/utils/request'
// import qs from 'qs'

export const login = (data, hasLoading = true) => {
  return request({
    method: 'POST',
    url: '/users/login',
    data,
    hasLoading
  })
}

export const sendResetPasswordEmail = (data, hasLoading = true) => {
  return request({
    url: '/users/send_reset_password',
    method: 'POST',
    data,
    hasLoading
  })
}

export const postLogout = (hasLoading = true) => {
  return request({
    url: '/users/logout',
    method: 'POST',
    hasLoading
  })
}

export const resetPassword = (data, hasLoading = true) => {
  return request({
    url: '/users/reset_password',
    method: 'POST',
    data,
    hasLoading
  })
}

export const getPermissionMenu = (hasLoading = true) => {
  return request({
    url: '/menus',
    method: 'GET',
    hasLoading
  })
}

export const getPermissionList = (hasLoading = true) => {
  return request({
    url: '/permissions',
    method: 'GET',
    hasLoading
  })
}

export const getUserList = (params, hasLoading = true) => {
  return request({
    url: '/users',
    method: 'GET',
    params,
    hasLoading
  })
}

export const getUserInfo = (id, hasLoading = true) => {
  return request({
    url: `/users/${id}`,
    method: 'GET',
    hasLoading
  })
}

export const createUser = (data, hasLoading = true) => {
  return request({
    url: '/users',
    method: 'POST',
    data,
    hasLoading
  })
}

export const updateUserInfo = (id, data, hasLoading = true) => {
  return request({
    url: `/users/${id}`,
    method: 'PUT',
    data,
    hasLoading
  })
}