import request from '@/utils/request'
export const getPatientList = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/patients',
    params,
    hasLoading
  })
}

export const createPatient = (data, hasLoading = true) => {
  return request({
    method: 'POST',
    url: '/patients',
    data,
    hasLoading
  })
}

export const getPatientInfo = (id, hasLoading = true) => {
  return request({
    method: 'GET',
    url: `/patients/${id}`,
    hasLoading
  })
}

export const getStatusLogList = (id, hasLoading = true) => {
  return request({
    method: 'GET',
    url: `/patients/${id}/statuses`,
    hasLoading
  })
}

export const updatePatientInfo = (id, data, hasLoading = true) => {
  return request({
    method: 'PUT',
    url: `/patients/${id}`,
    data,
    hasLoading
  })
}

export const updatePatientStatus = (id, url, data, method, hasLoading = true) => {
  return request({
    method: method || 'POST',
    url: url? `/patients/${id}/${url}`: `/patients/${id}`,
    data,
    hasLoading
  })
}

export const getPatientLogList = (id, hasLoading = true) => {
  return request({
    method: 'GET',
    url: `/patients/${id}/logs`,
    hasLoading
  })
}