<template>
	<div class="aside">
		<el-menu
			ref="appaside"
			@open="handleOpen"
			@close="handleClose"
			@select="handleSelect"
			background-color="#545c64"
			:unique-opened="true"
			text-color="#fff"
			active-text-color="#ffd04b"
			:collapse="!sidebar.opened"
			router
		>
			<template v-for="(item, index) in permissions">
				<template v-if="item.permit">
					<el-submenu :index="'parent' + item.name" :key="index" v-if="item.children && item.children.length > 0">
						<template slot="title">
							<i class="iconfont" :class="item.icon"></i>
							<span slot="title">{{item.title}}</span>
						</template>
						<div v-for="(child, childIndex) in item.children" :key="'child' + childIndex">
							<template v-if="child.permit">
								<el-menu-item :index="child.route" >
									<i class="iconfont" :class="child.icon"></i>
									<span slot="title">{{child.title}}</span>
								</el-menu-item>
							</template>
						</div>
					</el-submenu>
					<el-menu-item :key="'other' + index" :index="item.route" v-else>
						<i class="iconfont" :class="item.icon"></i>
						<span slot="title">{{item.title}}</span>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
	</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppAside',
  data () {
    return {
      collapse: true,
      app_env: process.env.NODE_ENV,
      app_name: process.env.VUE_APP_NAME
    }
  },
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      permissions: (state) => state.user.permissions
    }),
    classObj () {
      return {
        hideSidebar: this.sidebar.opened
      }
    }
  },
  watch: {
    $route () {
      this.getheader(true)
    }
  },
  created () {
    this.getheader(true)
  },
  methods: {
    // 获取首页
    getheader (flag) {
      const data = this.$route.matched.filter((item) => item.name)
      this.$nextTick(() => {
        if (data[0].name === 'dashboard') {
          this.$refs.appaside.activeIndex = '/'
          this.$refs.appaside.openedMenus = ['/']
        } else {
          if (flag) {
						const routePath = data[0].path.split('/')
						// const routeArray = [
						// 	routePath[1]
						// ]
						// if (routePath.length > 3) routeArray.push(routePath[2])
						// if (!routeArray.includes('list')) routeArray.push('list')
						//this.$refs.appaside.activeIndex = '/' + routeArray.join('/')
						let pPath = routePath[1]
						if (data[0].meta && data[0].meta.menu) {
							pPath = data[0].meta.menu
						}
            this.$refs.appaside.activeIndex = pPath
						this.$refs.appaside.openedMenus.push('parent' + routePath[1])
          }
        }
      })
    },
    handleOpen (key, keyPath) {
      // console.log('1', key, keyPath)
      // console.log(this.$refs.appaside)
    },
    //
    handleClose (key, keyPath) {
      // console.log('2', key, keyPath)
    },
    //
    handleSelect (key, keyPath) {
      // this.getMenuName(keyPath[0])
    },
    getMenuName (index) {
      this.$store.commit(
        'app/TOGGLE_NAME',
        this.map.get(index) === undefined ? '' : this.map.get(index)
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.aside {
	height: 100%;
	// 菜单收缩栏样式(关闭时)
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 200px;
		min-height: 400px;
	}
	.el-menu:not(.el-menu--collapse) {
		width: 200px;
	}
	.el-menu {
		height: 100%;
	}
	.newSpan {
		transform: translateY(-50%);
		width: 36px;
		height: 16px;
		background: #d74747;
		color: #fff;
		font-size: 12px;
		border-radius: 8px;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.hideNewSpan {
		top: 50%;
		right: 40px;
	}
	.openNewSpan {
		top: 30%;
		right: 0;
	}
}
</style>
<style lang="scss">
.aside {
	.el-menu {
		.el-tooltip {
			padding-left: 17px !important;
		}
		.el-menu-item i {
			color: #fff;
			font-size: 14px;
			padding-right: 6px;
		}
		.el-submenu {
			.el-submenu__title {
				display: flex;
				align-items: center;
			}
			.el-submenu__title span {
				white-space: normal;
				word-break: break-word;
				line-height: 20px;
				flex: 1;
				padding-right: 20px;
			}
			.el-submenu__title i {
				color: #fff;
				padding-right: 6px;
			}
			.el-menu-item {
				display: flex;
				align-items: center;
				background-color: #3c434a !important;
				padding-right: 20px !important;
				&:hover {
					background-color: #2c3238 !important;
				}
			}
			.el-menu-item span {
				white-space: nowrap;
				word-break: break-word;
				line-height: 20px;
				flex: 1;
			}
			.el-menu-item {
				padding-left: 20px !important;
			}
		}
		.is-active {
			> div {
				color: rgb(255, 208, 75) !important;
				> i {
					color: rgb(255, 208, 75) !important;
				}
			}
			> i {
				color: rgb(255, 208, 75) !important;
			}
		}
	}
}
.el-menu--vertical {
	.el-menu-item i {
		color: #fff;
		padding-right: 6px;
			
	}
}

</style>
