const bedsArrangementManager = [
  {
    path: '/bedsArrangement',
    component: () => import('@/views/bed-arrangement/index.vue'),
    meta: { name: 'Bed Arrangement', allowBreadcrumbRedirect: true },
    redirect: {
      name: 'bedsArrangementList'
    },
    children: [
      {
        path: 'list',
        name: 'bedsArrangementList',
        component: () => import('@/views/bed-arrangement/list/index.vue'),
        meta: { name: '', keepAlive: true, menu: '/bedsArrangement/list'  }
      }
    ]
  }
]

export default bedsArrangementManager