const tcuManager = [
  {
    path: '/tcuManagement',
    component: () => import('@/views/tcu-management/index.vue'),
    meta: { name: 'TCU Management', allowBreadcrumbRedirect: false },
    redirect: {
      name: 'tcuManagementList'
    },
    children: [
      {
        path: 'list',
        name: 'tcuManagementList',
        component: () => import('@/views/tcu-management/list/index.vue'),
        meta: { name: 'List', keepAlive: true, menu: '/tcuManagement/list'  }
      }
    ]
  }
]

export default tcuManager