import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
// import 'element-ui/lib/theme-chalk/index.css'
// 加载全局样式
import './styles/index.scss'
import animated from 'animate.css'
import VueCookies from 'vue-cookies'
import './router/permission'
Vue.use(VueCookies)
Vue.use(animated)
Vue.use(ElementUI, {
  size: 'small',
  locale
})

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
