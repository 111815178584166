const billingManager = [
  {
    path: '/billing',
    component: () => import('@/views/billing/index.vue'),
    meta: { name: 'Billing', allowBreadcrumbRedirect: false },
    redirect: {
      name: 'billingList'
    },
    children: [
      {
        path: 'list',
        name: 'billingList',
        component: () => import('@/views/billing/list/index.vue'),
        meta: { name: 'List', keepAlive: true, menu: '/billing/list'  }
      }
    ]
  }
]

export default billingManager