const usersManager = [
  {
    path: '/users',
    component: () => import('@/views/users/index.vue'),
    meta: { name: 'Users', allowBreadcrumbRedirect: true },
    redirect: {
      name: 'usersList'
    },
    children: [
      {
        path: '/users/list',
        name: 'usersList',
        component: () => import('@/views/users/list/index.vue'),
        meta: { name: 'List', keepAlive: true, menu: '/users/list'  }
      },
      {
        path: '/users/update',
        name: 'usersUpdate',
        component: () => import('@/views/users/update/index.vue'),
        meta: { name: 'Add', keepAlive: true, menu: '/users/list'}
      },
      {
        path: '/users/details',
        name: 'usersDetails',
        component: () => import('@/views/users/details/index.vue'),
        meta: { name: 'View', keepAlive: true, menu: '/users/list'}
      }
    ]
  }
]

export default usersManager