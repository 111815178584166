const workplaceManager = [
  {
    path: '/workplace',
    component: () => import('@/views/workplace/index.vue'),
    meta: { name: 'Workplace', allowBreadcrumbRedirect: true },
    redirect: {
      name: 'workplaceList'
    },
    children: [
      {
        path: '/workplace/list',
        name: 'workplaceList',
        component: () => import('@/views/workplace/patient-list/index.vue'),
        meta: { name: '', keepAlive: true, menu: '/workplace/list'}
      },
      {
        path: '/workplace/patientUpdate',
        name: 'wrokplacePatientUpdate',
        component: () => import('@/views/workplace/update-patient/index.vue'),
        meta: { name: 'List New Patient', keepAlive: true, menu: '/workplace/list'}
      },
      {
        path: '/workplace/patientInfo',
        component: () => import('@/views/workplace/patient-info/index.vue'),
        meta: {name: 'Record Details'},
        redirect: {
          name: 'wrokplacePatientInfoDetails'
        },
        children: [
          {
            path: 'details',
            name: 'wrokplacePatientInfoDetails',
            component: () => import('@/views/workplace/patient-info/details/index.vue'),
            meta: { name: 'View', keepAlive: true, menu: '/workplace/list'  }
          },
          {
            path: 'update',
            name: 'wrokplacePatientInfoUpdate',
            component: () => import('@/views/workplace/patient-info/update/index.vue'),
            meta: { name: 'Edit', keepAlive: true, menu: '/workplace/list'  }
          }
        ]
      }
    ]
  }
]

export default workplaceManager