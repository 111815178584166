import request from '@/utils/request'
//Institution
export const getInstitutionList = (params, hasLoading = true) => {
  return request({
    url: `/institutions`,
    method: 'GET',
    params,
    hasLoading
  })
}

export const getInstitutionInfo = (id, hasLoading = true) => {
  return request({
    url: `/institutions/${id}`,
    method: 'GET',
    hasLoading
  })
}

export const createInstitution = (data, hasLoading = true) => {
  return request({
    url: '/institutions',
    method: 'POST',
    data,
    hasLoading
  })
}

export const updateInstitutionInfo = (id, data, hasLoading= true) => {
  return request({
    url: `/institutions/${id}`,
    method: 'PUT',
    data,
    hasLoading
  })
}

//Discharge Destination
export const getDestinationList = (params, hasLoading = true) => {
  return request({
    url: `/destinations`,
    method: 'GET',
    params,
    hasLoading
  })
}

export const getDestinationInfo = (id, hasLoading = true) => {
  return request({
    url: `/destinations/${id}`,
    method: 'GET',
    hasLoading
  })
}

export const createDestination = (data, hasLoading = true) => {
  return request({
    url: '/destinations',
    method: 'POST',
    data,
    hasLoading
  })
}

export const updateDestinationInfo = (id, data, hasLoading = true) => {
  return request({
    url: `/destinations/${id}`,
    method: 'PUT',
    data,
    hasLoading
  })
}

//Claim Diagnosis
export const getDiagnosisList = (params, hasLoading = true) => {
  return request({
    url: `/diagnoses`,
    method: 'GET',
    params,
    hasLoading
  })
}

export const getDiagnosisInfo = (id, hasLoading = true) => {
  return request({
    url: `/diagnoses/${id}`,
    method: 'GET',
    hasLoading
  })
}

export const createDiagnosis = (data, hasLoading = true) => {
  return request({
    url: '/diagnoses',
    method: 'POST',
    data,
    hasLoading
  })
}

export const updateDiagnosisInfo = (id, data, hasLoading = true) => {
  return request({
    url: `/diagnoses/${id}`,
    method: 'PUT',
    data,
    hasLoading
  })
}

//Beds
export const getBedList = (params, hasLoading = true) => {
  return request({
    url: `/beds`,
    method: 'GET',
    params,
    hasLoading
  })
}

export const getBedInfo = (id, hasLoading = true) => {
  return request({
    url: `/beds/${id}`,
    method: 'GET',
    hasLoading
  })
}

export const createBed = (data, hasLoading = true) => {
  return request({
    url: '/beds',
    method: 'POST',
    data,
    hasLoading
  })
}

export const updateBedInfo = (id, data, hasLoading = true) => {
  return request({
    url: `/beds/${id}`,
    method: 'PUT',
    data,
    hasLoading
  })
}