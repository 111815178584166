import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'
//import store from '@/store'
import workplaceManager from './modules/workplaceManager'
import usersManager from './modules/usersManager'
import resourcesManager from './modules/resourcesManager'
import bedsArrangementManager from './modules/bedArrangementManager'
import tcuManager from './modules/tcuManager'
import billingManager from './modules/billingManager'
import messageBoxManager from './modules/messageBoxManager'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 路由配置规则
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import('@/views/reset-password/index.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error-page/404.vue'),
    hidden: true
  },
  {
    path: '/nopermission',
    name: 'nopermission',
    component: () => import('@/views/error-page/no-permission.vue'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '', // 默认子路由
        name: "dashboard",
        component: () => import('@/views/dashboard/index.vue'),
        meta: { name: 'Dashboard', show: true, index: '/', menu: true }
      },
      ...workplaceManager,
      ...bedsArrangementManager,
      ...tcuManager,
      ...usersManager,
      ...billingManager,
      ...messageBoxManager,
      ...resourcesManager
    ]
  },
  { path: '*', redirect: '/404', hidden: true }
]
const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   const {token} = store.state.user
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!token && to.path !== '/login') {
//       next({
//         name: 'login',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     } else {
//       next()
//     }
//   } else {
//     next()
//   }
// })
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
