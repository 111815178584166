<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data () {
    return {
      screenWidth: document.body.clientWidth
    }
  },
  watch: {
    $route: {
      handler (val) {
        if (!this.$store.state.app.initIndex) {
          if (this.$route.name !== 'login' && this.$route.name !== 'resetPassword') {
            this.$store.dispatch('user/initUserInfo')
          }
          this.$store.commit('app/INIT_ADD', true)
        }
      }
    }
  },
  created () {
    if (this.screenWidth < 426) {
      this.$store.commit('app/TOGGLE_PHONE', true)
    }
  },
  mounted () {
    this.$store.commit('app/INIT_ADD', false)
  }
}
</script>

<style lang="scss">
</style>
