import {
  getPermissionMenu,
  getPermissionList,
  postLogout,
  getUserInfo
} from '@/services'
const state = {
  user: JSON.parse(localStorage.getItem('user')) || null,
  token: localStorage.getItem('token') || '',
  permissions: JSON.parse(localStorage.getItem('permissions')) || [],
  role: '',
  permitActions: []
}

const mutations = {
  SET_USER: (state, payload) => {
    state.user = payload
    if (payload) {
      localStorage.setItem('user', JSON.stringify(payload))
    } else {
      localStorage.removeItem('user')
    }
  },
  SET_PERMIT_MENU: (state, payload) => {
    state.permissions = payload
    if (payload) {
      localStorage.setItem('permissions', JSON.stringify(payload))
    } else {
      localStorage.removeItem('permissions')
    }
  },
  SET_TOKEN: (state, payload) => {
    state.token = payload
    if (payload)
      localStorage.setItem('token', payload)
    else 
      localStorage.removeItem('token')
  },
  SET_ROLE: (state, payload) => {
    state.role = payload
  },
  SET_PERMIT_ACTIONS: (state, payload) => {
    state.permitActions = payload
  }
}

const actions = {
  setUserInfo ({ commit, dispatch }, payload) {
    commit('SET_USER', payload)
    dispatch('setRole', payload.role)
    dispatch('setPermitMenu')
  },
  async setPermitMenu ({commit, dispatch}) {
    try {
      const token = localStorage.getItem('token') || ''
      if (token) {
        dispatch('setToken', token)
        const res = await getPermissionMenu(false)
        if (res.data.menus) {
          commit('SET_PERMIT_MENU', res.data.menus)
        }
      }
    } catch (error) {
      
    }
  },
  async setPermitActions ({commit}) {
    try {
      const res = await getPermissionList(false)
      if (res.data.permissions) {
        commit('SET_PERMIT_ACTIONS', res.data.permissions)
      }
    } catch (error) {
      
    }
  },
  setRole ({commit}, payload) {
    commit('SET_ROLE', payload)
  },
  setToken ({commit}, payload) {
    commit('SET_TOKEN', payload)
  },
  async postLogout ({commit}, cb) {
    try {
      const data = await postLogout() 
      if (data.code >= 200 && data.code < 300) {
        localStorage.clear()
        commit('SET_USER', null)
        commit('SET_TOKEN', '')
        cb()
      }
    } catch (error) {
      
    }
  },
  async initUserInfo ({commit, dispatch}) {
    try {
      if (state.user) {
        dispatch('setPermitMenu')
        const data = await getUserInfo(state.user.id, false)
        if (data.data.user) {
          commit('SET_USER', data.data.user)
          //dispatch('setUserInfo', data.data.user)
        }
      }
    } catch (error) {}
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
