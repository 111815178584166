<template>
	<!-- <div class="appHearBox"> -->
	<div class="header">
		<div class="rightBox">
			<div class="rightSwich leftPoint" @click="backToSilder">
				<svg :class="{'is-active':sidebar.opened}" class="hamburger" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="64" height="64">
					<path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
				</svg>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<!-- <el-breadcrumb-item v-if="sidebar.name !== ''">{{sidebar.name}}</el-breadcrumb-item> -->
				<el-breadcrumb-item v-for="(item,index) in routerList" :key="index" :to="gotoPath(item, index)">{{item.meta.name}}</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div class="rightBox">
			<div v-if="hasNotice" class="rightBox_notice" @click="goHandler">
				<i class="iconfont icon-notice"></i>
				<div v-if="hasNewNotice" class="rightBox_notice_dot"></div>
			</div>
			<span class="rightBox_welcome">Welcome, </span>
			<span class="rightBox_name">{{ userInfo && userInfo.name }}</span>
			<el-dropdown>
				<span class="el-dropdown-link">
					<el-avatar shape="square" :size="40" :src="(userInfo && userInfo.avatar_url)|| require(`@/assets/avatar.svg`)"></el-avatar>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="handleAdmin">{{ userInfo && userInfo.name }}</el-dropdown-item>
					<el-dropdown-item divided @click.native="handleLogout">Logout</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
	<!-- </div> -->
</template>

<script>
import screenfull from 'screenfull'
import { mapState } from 'vuex'
import router from '@/router/index.js'
import {
  getNoticeList
} from '@/services'
import utils from '@/utils/Utils'
export default {
	name: 'AppHeader',
	data() {
		return {
			imgurl: process.env.VUE_APP_NAME,
			routerList: [],
			// userInfo: {}, // 当前登录用户信息
			fullScreenVisible: false,
			hasNewNotice: false,
			hasNotice: true
		}
	},
	computed: {
		...mapState({
			sidebar: (state) => state.app.sidebar,
			userInfo: (state) => state.user.user,
			menu: (state) => state.user.permissions
		})
	},
	watch: {
		$route() {
			this.getBreadcrumb()
			this.initNoticeList()
		},
	},
	created() {
		// this.userInfo = this.$store.state.user
		this.getBreadcrumb()
		this.initNoticeList()
	},
	destroyed() {
		screenfull.on('change', this.getScreenStatus)
	},
	methods: {
		// 前往管理员编辑
		handleAdmin() {
			this.$router.push({
				name: 'usersDetails',
				query: {
					id: this.userInfo.id,
				},
			})
		},
		async initNoticeList () {
      try {
				const noticeMenu = this.menu.filter((m) => m.route == '/messageBox/list')
				if (noticeMenu.length < 0 || !noticeMenu[0].permit) {
					this.hasNotice = false
					return
				}
        const res = await getNoticeList({
          page: this.page,
          per_page: this.pageSize,
          status_eq: 'unread'
        })
        if (res.data.notices && res.data.notices.length > 0) {
					this.hasNewNotice = true
        }
      } catch (error) {}
    },
		// 管理菜单栏
		backToSilder() {
			this.$store.dispatch('app/toggleSideBar')
		},
		gotoPath(item, index) {
			if (item.meta && item.meta.allowBreadcrumbRedirect) {
				return { path: item.path }
			}
		},
		getFullScreen() {
			screenfull.toggle()
			screenfull.on('change', this.getScreenStatus)
		},
		getScreenStatus() {
			if (screenfull.isFullscreen) {
				// 全屏时，要执行的操作
				this.fullScreenVisible = true
			} else {
				// 取消全屏时，要执行的操作
				this.fullScreenVisible = false
			}
		},
		// 获取面包屑路由
		getBreadcrumb() {
			const _this = this
			const routes = router.options.routes
			const active = []
			let deep = 0
			let flag = false
			function matchRoutes(rou) {
				for (let i = 0; i < rou.length; i++) {
					if (flag) {
						return active
					} else {
						const item = rou[i]
						active[deep] = i
						if (item.name === _this.$route.name) {
							flag = true
							return active
						}
						if (item.children && item.children.length > 0) {
							deep++
							matchRoutes(item.children)
						}
					}
				}
				if (!flag) {
					deep--
					active.length = deep
				}
			}
			const tmpArr = matchRoutes(routes)
			const arr = []
			tmpArr.forEach((item, index) => {
				if (index === 0) {
					const obj = routes[tmpArr[0]]
					if (obj.meta && obj.meta.name) {
						arr.push(obj)
					}
				} else {
					let obj = routes[tmpArr[0]]
					for (let i = 0; i < index; i++) {
						obj = obj.children[tmpArr[i + 1]]
					}
					if (obj.meta && obj.meta.name) {
						arr.push(obj)
					}
				}
			})
			this.routerList = arr
		},
		async loadUserInfo() {
			// const { data } = await getUserInfo()
			// this.userInfo = data.content
		},
		async handleLogout() {
			try {
				const res = await utils.showConfirmDialog(this, 'Tips', 'Are you sure to log out?', 'Confirm')
				if (res == 'confirm') {
					this.$store.dispatch('user/postLogout', () => {
						this.$message({
							type: 'success',
							message: 'Exit Success!',
						})
						// 跳转到登录页面
						this.$router.push({
							name: 'login',
						})
					})
				}
			} catch (error) {
			}
		},
		goHandler () {
			this.$router.push({
				name: 'messageBoxList'
			})
		}
	},
}
</script>

<style lang="scss" scoped>
.header {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.el-dropdown-link {
		cursor: pointer;
		display: flex;
		align-items: center;
	}
	.rightBox {
		display: flex;
		align-items: center;
		.iconfont {
			font-size: 20px;
			color: $text-color-darkblue;
			font-weight: bold;
		}
		.rightSwich {
			margin-right: 16px;
		}
		&_name {
			font-size: 14px;
			color: $text-color-darkblue;
			padding-right: 8px;
		}
		&_welcome {
			color: #5C5C5C;
			font-size: 14px;
			padding-right: 8px;
		}
		&_notice {
			position: relative;
			margin-right: 10px;
			cursor: pointer;
			&_dot {
				position: absolute;
				width: 7px;
				height: 7px;
				background: $danger-color;
				border-radius: 7px;
				right: 0;
				top: -2px;
			}
		}
	}
	.leftPoint {
		cursor: pointer;
	}
	.hamburger {
		display: inline-block;
		vertical-align: middle;
		width: 20px;
		height: 20px;
	}

	.hamburger.is-active {
		transform: rotate(180deg);
	}
}
</style>
