const resourcesManager = [
  {
    path: '/resources',
    component: () => import('@/views/resources/index.vue'),
    meta: { name: 'Resources', allowBreadcrumbRedirect: false },
    children: [
      {
        path: '/resources/institutions',
        component: () => import('@/views/resources/institutions/index.vue'),
        meta: { name: 'Institutions', allowBreadcrumbRedirect: true },
        redirect: {
          name: 'resourcesInstitutionsList'
        },
        children: [
          {
            path: 'list',
            name: 'resourcesInstitutionsList',
            component: () => import('@/views/resources/institutions/list/index.vue'),
            meta: { name: 'List', keepAlive: true, menu: '/resources/institutions/list'  }
          },
          {
            path: 'update',
            name: 'resourcesInstitutionsUpdate',
            component: () => import('@/views/resources/institutions/update/index.vue'),
            meta: { name: 'Add', keepAlive: true, menu: '/resources/institutions/list'}
          },
          {
            path: 'details',
            name: 'resourcesInstitutionsDetails',
            component: () => import('@/views/resources/institutions/details/index.vue'),
            meta: { name: 'View', keepAlive: true, menu: '/resources/institutions/list'}
          }
        ]
      },
      {
        path: '/resources/destination',
        component: () => import('@/views/resources/destination/index.vue'),
        meta: { name: 'Discharge Destination', allowBreadcrumbRedirect: true },
        redirect: {
          name: 'resourcesDestinationList'
        },
        children: [
          {
            path: 'list',
            name: 'resourcesDestinationList',
            component: () => import('@/views/resources/destination/list/index.vue'),
            meta: { name: 'List', keepAlive: true, menu: '/resources/destination/list'}
          },
          {
            path: 'update',
            name: 'resourcesDestinationUpdate',
            component: () => import('@/views/resources/destination/update/index.vue'),
            meta: { name: 'Add', keepAlive: true, menu: '/resources/destination/list'}
          },
          {
            path: 'details',
            name: 'resourcesDestinationDetails',
            component: () => import('@/views/resources/destination/details/index.vue'),
            meta: { name: 'View', keepAlive: true, menu: '/resources/destination/list'}
          }
        ]
      },
      {
        path: '/resources/diagnosis',
        component: () => import('@/views/resources/diagnosis/index.vue'),
        meta: { name: 'Claim Diagnosis', allowBreadcrumbRedirect: true },
        redirect: {
          name: 'resourcesDiagnosisList'
        },
        children: [
          {
            path: 'list',
            name: 'resourcesDiagnosisList',
            component: () => import('@/views/resources/diagnosis/list/index.vue'),
            meta: { name: 'List', keepAlive: true, menu: '/resources/diagnosis/list'}
          },
          {
            path: 'update',
            name: 'resourcesDiagnosisUpdate',
            component: () => import('@/views/resources/diagnosis/update/index.vue'),
            meta: { name: 'Add', keepAlive: true, menu: '/resources/diagnosis/list'}
          },
          {
            path: 'details',
            name: 'resourcesDiagnosisDetails',
            component: () => import('@/views/resources/diagnosis/details/index.vue'),
            meta: { name: 'View', keepAlive: true, menu: '/resources/diagnosis/list'}
          }
        ]
      },
      {
        path: '/resources/beds',
        component: () => import('@/views/resources/beds/index.vue'),
        meta: { name: 'Beds', allowBreadcrumbRedirect: true },
        redirect: {
          name: 'resourcesBedsList'
        },
        children: [
          {
            path: 'list',
            name: 'resourcesBedsList',
            component: () => import('@/views/resources/beds/list/index.vue'),
            meta: { name: 'List', keepAlive: true, menu: '/resources/beds/list'}
          },
          {
            path: 'update',
            name: 'resourcesBedsUpdate',
            component: () => import('@/views/resources/beds/update/index.vue'),
            meta: { name: 'Add', keepAlive: true, menu: '/resources/beds/list'}
          },
          {
            path: 'details',
            name: 'resourcesBedsDetails',
            component: () => import('@/views/resources/beds/details/index.vue'),
            meta: { name: 'View', keepAlive: true, menu: '/resources/beds/list'}
          }
        ]
      }
    ]
  }
]

export default resourcesManager