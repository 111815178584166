import router from '@/router'
import store from '@/store'

const whiteList = ['/login', '/resetPassword'] 
router.beforeEach(async(to, from, next) => {
  let {token, permissions} = store.state.user
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token) {
      if (permissions.length == 0) {
        await store.dispatch('user/setPermitMenu')
        permissions = store.state.user.permissions
      }
      const idx = permissions.findIndex((d) => {
        return (d.children.length == 0 && (to.path == d.route || (to.path.includes('/' + d.name))) && d.permit) ||
          (d.children.length > 0 && 
          (d.children.some((c) => (to.path.includes('/' + d.name + '/' + c.name) || 
            to.path.includes('/' + d.name + '/' + c.name.slice(0, c.name.length - 1)))
          && c.permit)))
      })
      if (idx >= 0) {
        next()
      } else {
        next('/nopermission')
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next()
      } else {
        next(`/login?redirect=${to.path}`)
      }
    }
  } else {
    next()
    // if (token && to.path == '/login') {
    //   next({ path: '/' })
    // } else {
    //   next()
    // }
  }
})
