import request from '@/utils/request'
export const getTcuList = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/tcus',
    params,
    hasLoading
  })
}

export const createTcu = (data, hasLoading = true) => {
  return request({
    method: 'POST',
    url: '/tcus',
    data,
    hasLoading
  })
}

export const postTcuAction = (method, url, data, hasLoading = true) => {
  return request({
    method,
    url,
    data,
    hasLoading
  })
}

export const updateTcuInfo = (id, patientId, data, hasLoading = true) => {
  data['tcu'].patient_id = patientId
  return request({
    method: 'PUT',
    url: `/tcus/${id}`,
    data,
    hasLoading
  })
}

export const getTcuInfo = (id, params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: `/tcus/${id}`,
    params,
    hasLoading
  })
}

export const getNotesList = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/notes',
    params,
    hasLoading
  })
}