export default {
  notifyInstances: [],
  dateFormat (fmt, datename) {
    let ret
    const date = new Date(datename)
    const opt = {
      'Y+': date.getFullYear().toString(), // 年
      'm+': (date.getMonth() + 1).toString(), // 月
      'd+': date.getDate().toString(), // 日
      'H+': date.getHours().toString(), // 时
      'M+': date.getMinutes().toString(), // 分
      'S+': date.getSeconds().toString() // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    }
    for (const k in opt) {
      ret = new RegExp('(' + k + ')').exec(fmt)
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, '0')))
      }
    }
    return fmt
  },
  getTime (dateStr) {
    var date = new Date(dateStr)
    var Month = date.getMonth() + 1
    var Day = date.getDate()
    var Y = date.getFullYear() + '-'
    var M = Month < 10 ? '0' + Month + '-' : Month + '-'
    var D = Day < 10 ? '0' + Day : Day
    return Y + M + D
  },
  getLaterDate (startdate, days) {
    const date = new Date(startdate)
    date.setDate(date.getDate() + days)
    return this.getTime(date)
  },
  compareTime (startTime, endTime) {
    // var start_time=startTime.replace(/-|\s|:|\//g,'').replace(' ', ''); // 用这个加强版也可以
    var start_time = startTime.replace(/-|\s|:|\//g, '')
    var end_time = endTime.replace(/-|\s|:|\//g, '')
    if (Number(start_time) <= Number(end_time)) {
      return true
    } else {
      return false
    }
  },
  isNull (data) {
    if (data === undefined || data === null || data === '') {
      return true
    } else {
      return false
    }
  },
  /**
   * 分转元
   * @param {Sting ｜ Number} num 分
   * @returns {Number} 元
   */
  centToYuan (num) {
    return Number(num) / 100
  },
  /**
   * 元转分
   * @param {Sting ｜ Number} num 元
   * @returns {Number} 分
   */
  yuanToCent (num) {
    return Number(num) * 100
  },
  /**
   * 添加千分符
   * @param {Sting ｜ Number} num
   * @returns {String}
   */
  numberFormat (num) {
    if (!num) return 0
    const numArr = num.toString().split('.')
    const reg = /\d{1,3}(?=(\d{3})+$)/g
    let a = numArr[0].replace(reg, '$&,')
    if (numArr[1]) {
      a = `${a}.${numArr[1]}`
    }
    return a
  },
  /**
   * money添加千分符自动保留两位小数
   * @param {Sting ｜ Number} num 元
   * @returns {String} 分
   */
  moneyFormat (num) {
    if (!num) return '0.00'
    const money = this.centToYuan(num).toFixed(2)
    return this.numberFormat(money)
  },
  isPlainObject (obj) {
    return Object.prototype.toString.call(obj) === '[object Object]'
  },
  isEmptyObject (obj) {
    return Object.keys(obj).length === 0
  },
  hasPermission (list, kind, action) {
    return list.includes('all#all') || list.includes(`${kind}#all`) || list.includes(`${kind}#${action}`)
  },
  encryptedStr (str) {
    return str.substring(0, 3) + '*'.repeat(3) + str.substring(6)
  },
  getAgeFromDate (date) {
    let birthday=new Date(date.replace(/-/g, "\/"))
    let d = new Date()
    return d.getFullYear() - birthday.getFullYear() - (
      (d.getMonth() < birthday.getMonth() || d.getMonth() == birthday.getMonth() 
      && d.getDate() < birthday.getDate()) ? 1 : 0)
  },
  showConfirmDialog (context, title, content, confirmButtonText = 'Delete', cancelButtonText = 'Cancel') {
    return new Promise((resolve, reject) => {
      context.$confirm(content, title, {
        confirmButtonText,
        cancelButtonText,
        cancelButtonClass: 'cancel-btn',
        confirmButtonClass: 'confirm-btn',
        customClass: 'confirm-dialog',
        type: 'warning'
      }).then(() => {
        resolve('confirm')
      }).catch(() => {
        reject('cencel')
      })
    })
  },
  closeAllNotification () {
    this.notifyInstances.forEach((d) => {
      if (d.obj) d.obj.close()
    })
  },
  showNoticeDialog (context, title, content, cb) {
    const id = 'notify' + this.notifyInstances.length
    const that = this
    let instance = context.$notify.info({
      title,
      message: content,
      showClose: false,
      duration: 12000,
      customClass: 'notice-dialog',
      onClick: function () {
        this.close()
        const idx = that.notifyInstances.findIndex((d) => d.id == id)
        that.notifyInstances.splice(idx, 1)
        if (cb) cb()
      }
    })
    this.notifyInstances.push({
      id,
      obj: instance
    })
  },
  getComputedValFromProp (info, object) {
    if (info.prop) {
      const prop = info.prop
      if (Object.hasOwn(object, prop) && typeof object[prop] === 'boolean') {
        return object[prop] ? 'Yes' : 'No'
      }
      if (Object.hasOwn(object, prop) && this.isPlainObject(object[prop]) && object[prop]) {
        if (info.objectKey) {
          if (info.objectKey == 'ic') {
            return object[prop][info.objectKey] && this.encryptedStr(object[prop][info.objectKey])
          }
          return object[prop][info.objectKey] || '-'
        } 
        return object[prop].name || '-'
      }
      if (prop == 'ic' && !info.isFull)  return object[prop] && this.encryptedStr(object[prop])
      if (info.unit) return object[prop] + ' ' + info.unit
      return object[prop] || '-'
    }
  },
  getComputedStatusName (list, status) {
    const idx = list.findIndex((d) => d.value == status)
    return idx >= 0 ? list[idx].name : '-'
  },
  requestSuccessCb (context, msg, s = 500, cb) {
    context.$message.success(msg)
    let timer = setTimeout(() => {
      clearTimeout(timer)
      cb()
    }, s)
  }
}
