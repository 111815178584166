import axios from 'axios'
import loadingJson from './loading.json'
import store from '@/store'
import { Message, Loading } from 'element-ui'
import router from '@/router'
//const clientId = _getClientId()
const BASE_URL = process.env.VUE_APP_BASE_URL

function _getClientId () {
  let id = localStorage.getItem('clientId')
  if (!id) {
    const randomStr = Math.round(Math.random() * 1000000)
    const timestamp = new Date().getTime()
    id = `Wechat_Mini_${timestamp}${randomStr}`
    localStorage.setItem('clientId', id)
  }
  return id
}

const request = axios.create({
  baseURL: BASE_URL + '/wf',
  timeout: 600000,
  headers: {
    'X-APP-KEY': 'Z1atkgygmbOTojjwQCQMYiWHhH4',
    //'X-CLIENT-ID': clientId,
    'Authorization': '',
    'X-USER-ID': ''
  }
})
let loadingInstance = null
function errorOperate (data) {
  if (data.response.data && data.response.data instanceof Blob) {
    var reader = new FileReader()
    reader.addEventListener('loadend', function (e) {
      Message.error(JSON.parse(e.target.result).errors[0])
    })
    reader.readAsText(data.response.data)
  } else if (data.response.data && data.response.data.errors) {
    Message.error(data.response.data.errors[0])
  } else if (data.response.data && data.response.data.error) {
    Message.error(data.response.data.error)
  }
}

// 请求拦截器
request.interceptors.request.use(function (config) {
  if (config.hasLoading) loadingInstance = Loading.service(loadingJson)
  const user = JSON.parse(window.localStorage.getItem('user') || 'null')
  if (user && user.id) {
    config.headers['X-USER-ID'] = user.id.toString()
  }
  config.headers['Authorization'] = localStorage.getItem('token') || ''
  config.headers.Accept = 'application/json'
  return config
}, function (error) {
  return Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(function (response) {
  if (loadingInstance) loadingInstance.close()
  if (response.status >= 200 && response.status < 300) {
    if (response.data.code >= 200 && response.data.code < 300) {
      if (response.headers.authorization) {
        store.commit('user/SET_TOKEN', response.headers.authorization)
      }
    } else if (response.data.code == 401) {
      store.commit('user/SET_TOKEN', '')
      localStorage.clear()
      router.push('/login')
    } else {
      Message.error(response.data.msg || 'Fail')
    }
    return Promise.resolve(response.data)
  } else {
    //错误情况
    return Promise.reject(response)
  }
}, async function (error) {
  if (loadingInstance) loadingInstance.close()
  if (error.response) { // 请求发出去收到响应了，但是状态码超出了 2xx 范围
    errorOperate(error)
  } else if (error.request) { // 请求发出去没有收到响应
    Message.error('请求超时，请刷新重试')
  } else {
    Message.error(`请求失败：${error.message}`)
  }
  return Promise.reject(error)
})

export default request
