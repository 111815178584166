import request from '@/utils/request'
export const getNoticeList = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/notices',
    params,
    hasLoading
  })
}

export const postReadNotice = (data, hasLoading = true) => {
  return request({
    method: 'POST',
    url: '/notices/read',
    data,
    hasLoading
  })
}

export const getMsgList = (params, hasLoading = true) => {
  return request({
    method: 'GET',
    url: '/messages',
    params,
    hasLoading
  })
}

export const createMsg = (data, hasLoading = true) => {
  return request({
    method: 'POST',
    url: '/messages',
    data,
    hasLoading
  })
}