<template>
  <el-container>
    <el-aside width="auto">
      <app-aside />
    </el-aside>
    <el-container>
      <el-header :height="headerHeight" class="width-limit">
        <app-header />
      </el-header>
      <el-main class="width-limit">
        <!-- 子路由出口 -->
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AppAside from './components/app-aside.vue'
import AppHeader from './components/app-header.vue'
import style from '@/styles/variables.scss'
export default {
  name: 'LayoutIndex',
  components: {
    AppAside,
    AppHeader
  },
  data () {
    return {
      headerHeight: style.headerHeight
    }
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  // min-height: 100vh;
  height: 100vh;
}

.el-aside {
  position: sticky;
  top: 0;
  max-height: 100vh;
  background: #d3dce6;
}

.el-header {
  background: #f8f9fb;
  position: sticky;
  top: 0;
  z-index: 100;
}

.el-main {
  background: #e9eef3;
}
</style>
